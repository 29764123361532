import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    contentRoot: {
      width: "100%",
      height: "100%",
      position: "relative",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mapContainer: {
      position: "relative",
      zIndex: 1,
      "&.leaflet-container": {
        width: "90vw",
        height: "400px",
      },
    },
  };
});

export default useStyles;
