import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: 36,
    backgroundColor: "#F2F5F8",
    flexGrow: 1,
  },
}));

export default useStyles;
