import { makeStyles } from "@mui/styles";
import { MenuImage } from "../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "100px 60px",
    display: "flex",
    flexDirection: "column",
    gap: 60,
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "100px 40px",
    },
  },
  heroWrapper: {
    position: "relative",
    background: `url(${MenuImage})`,
    backgroundRepeat: "no-repeat",
    height: "66vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  overlay: {
    backgroundColor: "#00000020",
    display: "flex",
    height: "inherit",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  imageText: {
    textAlign: "center",
    "&.MuiTypography-root": {
      color: "#FFFFFF",
    },
  },
  betterText: {
    "&.MuiTypography-root": {
      fontStyle: "italic",
      fontSize: 80,
      textAlign: "center",
    },
  },
  text: {
    WebkitTextStroke: "2px #000000",
  },
  scrollButton: {
    right: 25,
    bottom: 25,
    width: 50,
    height: 50,
    backgroundColor: `${theme.palette.info.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
  },
}));

export default useStyles;
