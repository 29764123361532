import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// css *required
import useStyles from "./styles";

// other init

//===================================================
// 1.Main Component
//===================================================

const ReservationSuccessModal = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // 1-2. Store
  const { isVisible, handleClose } = props;

  // showMain
  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle className={classes.headerText}>Reservation</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.text}>
          Thank you for your reservation! A member of our team will contact you
          shortly to confirm and assist with any additional details.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

//===================================================
// 2.Export
//===================================================
export default ReservationSuccessModal;
