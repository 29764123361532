import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";

import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

// css *required
import useStyles from "./styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "#f5f1e8",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#f5f1e8",
  textAlign: "center",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    justifyContent: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
}));

//===================================================
// 1.Main Component
//===================================================
const MenuCard = (props: any) => {
  const { menu } = props;

  // 1-1. useStyles *require
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Accordion
        key={menu.id}
        // expanded={expanded === `panel${index}`}
        // onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary>
          {menu.title && (
            <Typography variant="h4" align="center">
              {menu.title}
            </Typography>
          )}
        </AccordionSummary>

        <AccordionDetails>
          {menu.subTitle && (
            <Typography variant="h5" align="center">
              {menu.subTitle}
            </Typography>
          )}

          <Grid container spacing={[1, 2]}>
            {menu.items?.map((item: any) => {
              if (item.subMenu) {
                return (
                  <Grid item xs={12} key={item.id}>
                    <Box m={3}>
                      <Typography variant="h5" align="center" gutterBottom>
                        {item.subTitle}
                      </Typography>
                    </Box>

                    {item.nextInfo && (
                      <Grid item xs={12}>
                        <Typography
                          align="center"
                          variant="body1"
                          fontStyle="italic"
                          fontWeight="500"
                          gutterBottom
                        >
                          &ldquo;{item.nextInfo}&rdquo;
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                );
              }
              return (
                <React.Fragment key={item.id}>
                  <Grid item xs={12} className={classes.gridItem}>
                    <Typography variant="h5" align="center">
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={"300"}
                      align="right"
                    >
                      {item.price}
                    </Typography>
                  </Grid>
                  {item.subName && (
                    <Grid item xs={12} className={classes.subGridItem}>
                      <Typography variant="subtitle1" align="center">
                        {item.subName}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={"300"}
                        align="right"
                      >
                        {item.subPrice}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={24} className={classes.gridItem}>
                    <Typography variant="body1" fontWeight="300">
                      {item.description}
                    </Typography>
                  </Grid>
                  {item.nextInfo && (
                    <Grid item xs={24} className={classes.gridItem}>
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        fontStyle="italic"
                      >
                        &ldquo;{item.nextInfo}&rdquo;
                      </Typography>
                    </Grid>
                  )}

                  {item.addableItems && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.gridItem}>
                          <Typography variant="h6">
                            Available Additions
                          </Typography>
                        </Grid>
                        {item.addableItems.map(
                          (addableItem: any, index: number) => {
                            let gridSize = 12;
                            const itemCount = item.addableItems.length;

                            if (itemCount === 2) {
                              gridSize = 6;
                            } else if (itemCount >= 3) {
                              gridSize = 6;
                            }

                            return (
                              <Grid
                                key={addableItem.id}
                                item
                                xs={12}
                                sm={gridSize}
                                md={gridSize}
                                className={
                                  classes.gridItem +
                                  " " +
                                  classes.addableGridItem
                                }
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight="300"
                                  align="center"
                                >
                                  {addableItem.name}
                                </Typography>
                                <Typography
                                  align="right"
                                  variant="body1"
                                  fontWeight="300"
                                >
                                  {addableItem.price}
                                </Typography>
                              </Grid>
                            );
                          },
                        )}
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

//===================================================
// 2.Export
//===================================================
export default MenuCard;
