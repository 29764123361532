import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .MuiTypography-root": {
        color: "#945C44",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: 16,
      zIndex: 99,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    linkView: {
      display: "flex",
      gap: 8,
    },
  };
});

export default useStyles;
