import { Box, Link, Paper, Typography } from "@mui/material";
import useStyles from "./styles";
// atomic

// css *required

//===================================================
// 1.Main Component
//===================================================
const AppFooter = () => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // 1-2. Store
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  // showMain
  return (
    <Paper
      sx={{
        p: 4,
        boxShadow: "none",
        backgroundColor: "#D9BAA0",
        borderRadius: 0,
      }}
      className={classes.root}
    >
      <Box>
        <Typography variant="body2" fontWeight={"bold"}>
          THE BLISSMOMOS CAFE
        </Typography>
        <Typography variant="caption">
          {year} &copy; The Blissmomos cafe. All rights reserved.
        </Typography>
      </Box>
      <Box className={classes.linkView}>
        <Link
          href={`mailto:sunil.shrestha@blissmomoscafe.com`}
          target="_blank"
          color="inherit"
          fontWeight="bold"
        >
          Contact us
        </Link>
        <Typography variant="body1">/</Typography>
        <Link
          href="https://www.instagram.com/blissmomos/"
          target="_blank"
          color="inherit"
          fontWeight="bold"
        >
          Instagram
        </Link>
        <Typography variant="body1">/</Typography>
        <Link
          href="https://www.facebook.com/blissmomoswellard"
          target="_blank"
          color="inherit"
          fontWeight="bold"
        >
          Facebook
        </Link>
      </Box>
    </Paper>
  );
};

//===================================================
// 2.Export
//===================================================
export default AppFooter;
