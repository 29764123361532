import { ThemeProvider, createTheme } from "@mui/material";

import AppRouter from "./router";
import { Theme } from "@mui/material/styles";

const theme = createTheme();

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

//===================================================
// 1.Main Component
//===================================================
const App = () => {
  // 1-1. useStyles *require

  // 1-2. Store

  // showMain
  return showMain();
};

//===================================================
// 2.Export
//===================================================
export default App;

//===================================================
// 3.propTypes and defaultProps
//===================================================

//3-1. propTypes
App.propTypes = {};

//3-2. defaultProps
App.defaultProps = {};

//===================================================
// 4.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
};

//===================================================
// 5.Actions
//===================================================
