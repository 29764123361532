import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    gap: 40,
    display: "flex",
    flexDirection: "column",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column !important",
    },
  },
  addableGridItem: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "row !important",
    },
  },
  subGridItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column !important",
    },
    paddingTop: "0px !important",
  },
}));

export default useStyles;
