import { CssBaseline } from "@mui/material";

import { AppFooter, AppHeader, Contents } from "../../organisms";

// atomic
import useStyles from "./index.css";

// css *required

//===================================================
// 1.Main Component
//===================================================
const Dashboard = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // 1-2. Store
  const initialProps = {
    ...props,
    classes,
  };

  // showMain
  return showMain(initialProps);
};

//===================================================
// 2.Export
//===================================================
export default Dashboard;

//===================================================
// 3.propTypes and defaultProps
//===================================================

//3-1. propTypes
Dashboard.propTypes = {};

//3-2. defaultProps
Dashboard.defaultProps = {};

//===================================================
// 4.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props: any) => {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div>
        <AppHeader />
        <Contents>{children}</Contents>
      </div>
      <AppFooter />
    </div>
  );
};

//===================================================
// 5.Actions
//===================================================
