import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDbF8TT1FLftCIi_qOQL5UDL9Bhp3W29m0",
  authDomain: "blissmomoscafe.firebaseapp.com",
  projectId: "blissmomoscafe",
  storageBucket: "blissmomoscafe.appspot.com",
  messagingSenderId: "1073146285687",
  appId: "1:1073146285687:web:8ed6cbf5bb2587a22cc072",
  measurementId: "G-6HEP60KX9C",
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore and Functions
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db, functions };
