import { useEffect, useState } from "react";
import { Box, Typography, IconButton, useScrollTrigger } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

// atomic
import { Dashboard } from "../../templates";
import { MenuCard } from "../../organisms";

// css *required
import useStyles from "./styles";

//===================================================
// 1.Main Component
//===================================================
const Menu = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // Create a ref to store each Accordion element
  // const accordionRefs = useRef<HTMLDivElement[]>([]);

  // accordionRefs.current = [];

  // Set the title to your desired text
  useEffect(() => {
    document.title = "Cafe Menu - Bliss Momos";
  }, []);

  // Scroll-to-top button visibility logic
  const trigger = useScrollTrigger({ target: window, threshold: 100 });
  const [isVisible, setIsVisible] = useState(false);
  // const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    setIsVisible(trigger);
  }, [trigger]);

  // Scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handleChange =
  //   (panel: string, index: number ) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //   };

  return (
    <Dashboard>
      <div className={classes.heroWrapper}>
        <div className={classes.overlay}>
          <Typography
            variant="h3"
            fontWeight={"bold"}
            letterSpacing={4}
            className={classes.imageText}
          >
            <em className={classes.text}>Cafe Menu</em>
          </Typography>
        </div>
      </div>
      <Box className={classes.root}>
        {MENU_ITEMS.map((menu, index) => {
          return (
            <MenuCard
              // accordionRefs={accordionRefs}
              key={menu.id}
              menu={menu}
              index={index}
              // expanded={expanded}
              // handleChange={(panel: any)=>handleChange(panel, index)}
            />
          );
        })}
      </Box>
      <Typography variant="h4" align="center">
        Coffee, Food & Friendship
      </Typography>
      <Typography variant="h5" align="center" gutterBottom={true}>
        (Note: - Please Check our board menu for everyday specials or a talk to
        our friendly front of house staff)
      </Typography>
      {isVisible && (
        <IconButton
          aria-label="Scroll to top"
          color="primary"
          onClick={scrollToTop}
          className={classes.scrollButton}
          style={{
            position: "fixed",
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}
    </Dashboard>
  );
};

//===================================================
// 2.Export
//===================================================
export default Menu;

const MENU_ITEMS = [
  {
    id: "1",
    title: "ALL DAY BREAKFAST",
    items: [
      {
        id: "1",
        name: "AVOCADO SMASH",
        price: "21.50",
        description:
          "Freshly smashed avocado, cherry tomato, feta cheese and balsamic glaze served on toasted rosemary panini, finish with pumpkin seeds.",
        addableItems: [
          { id: "1", name: "Free range poached eggs", price: "5.00" },
          { id: "2", name: "Ham", price: "5.00" },
          { id: "3", name: "Salmon", price: "6.00" },
          { id: "4", name: "Bacon", price: "6.00" },
        ],
      },
      {
        id: "2",
        name: "EGGS ROYAL BENEDICT (Ham/Bacon/Salmon)",
        price: "23.00/24.00/24.00",
        subName: "choice on Lentile bread GF/DF extra",
        subPrice: "1.50",
        description:
          "Sautéed spinach, Free range poached eggs on toasted bread served with béarnaise sauce.",
      },
      {
        id: "3",
        name: "FIELDS OF MUSHROOM (vegetarian & vegan option available)",
        price: "24.00",
        description:
          "Sautéed mushrooms, spinach, Free range poached eggs stacked on toasted bread with basil pesto, goat’s cheese, and roasted capsicum and balsamic glaze on top.",
      },
      {
        id: "4",
        name: "CHEESY MUSHROOM OMELETTE (spicy option available)",
        price: "23.00",
        description:
          "Sautéed mushrooms & spinach, topped with melted mozzarella cheese. Served with a slice of toast.",
        addableItems: [
          { id: "1", name: "Ham", price: "5.00" },
          { id: "2", name: "Avocado", price: "5.00" },
        ],
      },
      {
        id: "5",
        name: "BIG BREAKFAST (vegetarian option available)",
        price: "25.00",
        description:
          "Crispy bacon, grilled tomato, sautéed mushrooms, pork sausages, hash brown and your own way cooked Eggs. Served with a slice of Toast. (Note: Veg option has no meat but added baked beans, avocado and wilted spinach).",
      },
      {
        id: "6",
        name: "EGGS ON TOAST",
        price: "13.50",
        description: "2 pieces of toasted bread served with Free Range Eggs.",
        addableItems: [
          { id: "1", name: "Bacon", price: "6.00" },
          { id: "2", name: "Hash Brown", price: "5.00" },
          { id: "3", name: "Avocado", price: "5.00" },
        ],
      },
      {
        id: "7",
        name: "BACON EGG & CHEESE TOASTIE with BARBEQUE SAUCE",
        price: "18.00",
        description: "",
      },
      {
        id: "8",
        name: "HAM & CHEESE TOASTIE with MAYONNAISE",
        price: "16.50",
        description: "",
      },
      {
        id: "9",
        name: "PULLED PORK EGGS BENEDICT ​(spicy option available)",
        price: "24.50",
        subName: "choice on LENTILE BREAD GF/DF EXTRA",
        subPrice: "1.50",
        description:
          "Shredded slow cooked pork on a bed of wilted spinach with free range poached eggs on top of toasted bread and béarnaise sauce.",
      },
      {
        id: "10",
        name: "Spicy Eggs Benedict (Ham/bacon/pulled pork/salmon)",
        price: "24/25/25/25",
        description:
          "Home-made mixed Lentil bread, wilted spinach, topped free range poached eggs with spicy hollandaise sauce.",
      },
      {
        id: "11",
        name: "SPICY CHORIZO STACK",
        price: "24",
        description:
          "Toasted ciabatta stacked with smashed avocado, sautéed mushrooms, chorizo, and free range poached eggs. Served with homemade tomato & beetroot sauce.",
      },
    ],
  },
  {
    id: "2",
    title: "SWEET BREAKFAST",
    items: [
      {
        id: "1",
        name: "AUROUS BLISSCAKE",
        price: "24.50",
        description:
          "Triple stacked of homemade fluffy pancakes served with caramelized banana, whipped cream or ice cream Garnished with almond flakes, icing sugar and mint leaves.",
      },
      {
        id: "2",
        name: "BACON PANCAKES",
        price: "24.50",
        description:
          "Double stacked of homemade fluffy pancakes served with grilled bacon, vanilla ice cream, maple syrup and poached mixed berries on top.",
      },
      {
        id: "3",
        name: "CARAMAL FRENCH TOAST",
        price: "25.50",
        description:
          "Slow cooked fluffy French toast topped with passion fruit, banana, strawberry with nuts and ice cream or cream.",
      },
      {
        id: "4",
        name: "BELGIAN WAFFLE",
        price: "23.00",
        description:
          "Toasted Belgian waffle, fresh fruits, ice cream with chocolate Sauce.",
        addableItems: [{ id: "1", name: "Bacon", price: "6.00" }],
      },
    ],
  },
  {
    id: "3",
    title: "JUNIORS MENU ",
    items: [
      {
        id: "1",
        name: "CHICKEN NUGGETS & CHIPS",
        price: "11.90",
      },
      {
        id: "2",
        name: "PANCAKES OR WAFFLE",
        price: "12.50",
      },
    ],
  },
  {
    id: "4",
    title: "WRAPS",
    items: [
      {
        id: "1",
        name: "CHICKEN AVOCADO WRAP ",
        price: "22.00",
        description:
          "Grilled chicken strips with cheddar cheese, spinach, tomato slices wrapped in toasted tortilla bread. Served with chips.",
      },
      {
        id: "2",
        name: "BREAKFAST WRAP",
        price: "19.00",
        description:
          "Grilled Bacon, Fried eggs, cheddar cheese, spinach, and barbeque sauce, wrapped in toasted tortilla bread, Served with tomato relish.",
      },
      {
        id: "3",
        name: "VEGAN WRAP",
        price: "22.50",
        description:
          "Fresh spinach, fresh tomato, sauteed mushrooms, hash brown, vegan mayo wrapped in toasted tortilla bread.",
      },
      {
        id: "4",
        name: "SPECIAL WRAP",
        price: "24.00",
        description:
          "Grilled bacon, free range fried eggs, hash brown, cheese and béarnaise sauce wrapped in toasted tortilla bread.",
      },
    ],
  },
  {
    id: "5",
    title: "SANDWICHES",
    items: [
      {
        id: "1",
        name: "BLT",
        price: "21.00",
        description:
          "Crispy bacon, mix lettuce and tomato slices in a toasted Turkish bread. Served with chips.",
      },
      {
        id: "2",
        name: "CHICKEN AND AVOCADO FOCACCIA",
        price: "22.50",
        description:
          "Grilled chicken toppings on focaccia with melted cheese, avocado slices and fresh spinach. Served with chips.",
      },
      {
        id: "3",
        name: "CLUB SANDWICH",
        price: "25.00",
        description:
          "Triple stacked Grilled chicken, bacon, fresh tomato, cos lettuce, melted cheese, tomato relish. Served with chips.",
      },
      {
        id: "4",
        name: "STEAK SANDWICH",
        price: "22.00",
        description:
          "Grilled rump steak in toasted Turkish bread with melted cheddar cheese, caramelized onion, masculine, tomato and roasted capsicum. Served with chips.",
      },
    ],
  },
  {
    id: "6",
    title: "SALADS",
    items: [
      {
        id: "1",
        name: "QUINOA SALAD",
        price: "23.00",
        description:
          "Cooked Chickpea, red & green Capsicum, chopped tomato, Cucumber, red onion, baby spinach, olive oil and lemon dressing.",
        addableItems: [
          { id: "1", name: "Grilled Chicken", price: "6.00" },
          { id: "2", name: "Boiled Eggs", price: "6.00" },
          { id: "3", name: "Grilled Prawn", price: "8.00" },
        ],
      },
      {
        id: "2",
        name: "CHICKEN AVOCADO SALAD",
        price: "23.00",
        description:
          "Grilled Chicken, avocado, fresh mix lettuce, cherry tomato, cucumber, capsicum, and red onion, finished with balsamic dressing.",
      },
    ],
  },
  {
    id: "7",
    title: "LUNCH MENU",
    items: [
      {
        id: "1",
        name: "BEEF BURGER WITH THE LOT",
        price: "25.00",
        description:
          "Grilled homemade beef patty stacked with grilled bacon, fried egg, fresh lettuce, tomato, red onion, caramelized onion, and cheddar cheese melted in a toasted brioche bun. Served with chips.",
      },
      {
        id: "2",
        name: "FISH AND CHIPS",
        price: "25.00",
        description:
          "Beer battered flathead fish fillets served with garden salad, side of chips, lemon wedge and tartar sauce.",
      },
      {
        id: "3",
        name: "CREAMY GARLIC PRAWN PASTA",
        price: "25.50",
        description:
          "Pan-fried garlic prawns with our fresh homemade creamy garlic sauce served in Fettuccini pasta and topping with Parmesan cheese and parsley.",
      },
    ],
  },
  {
    id: "8",
    title: "NEPALESE SPECIALS",
    subTitle: "(Bliss Momos signature dish Nepalese Style Dumplings)",
    items: [
      {
        id: "1",
        name: "MOMOS (Chicken/Beef)",
        price: "24.00",
        description:
          "Handmade Dumplings filled with minced meat & Vegetables, served with homemade tomato base Sauce. ",
        addableItems: [
          { id: "1", name: "Fried Dumplings available Extra", price: "5.00" },
          { id: "2", name: "Chilli Dumplings Extra", price: "6.00" },
        ],
      },
      {
        id: "2",
        name: "MOMOS (Vegetarian)",
        price: "24.00",
        description:
          "Handmade Dumplings filled with minced Vegetables and ricotta cheese, served with homemade tomato base Sauce.",
        addableItems: [
          { id: "1", name: "Fried Dumplings available Extra", price: "5.00" },
          { id: "2", name: "Chilli Dumplings Extra", price: "6.00" },
        ],
      },
      {
        id: "3",
        name: "CHOWMEIN CHICKEN",
        price: "22.00",
        description:
          "Nepalese style spiced Hokkien Noodles with stir-fried chicken, vegetables flavoured of dash of soy and sweet chili sauce finished with coriander.",
      },
      {
        id: "4",
        name: "VEGETABLE CHOWMEIN with EGG",
        price: "21.50",
        description:
          "Nepalese style spiced Hokkien Noodles with stir-fried vegetables flavoured of dash of soy and sweet chili sauce finished with coriander.",
      },
    ],
  },
  {
    id: "9",
    title: "DINNER MENU",
    items: [
      {
        id: "0",
        subMenu: true,
        subTitle: "Entrée",
      },
      {
        id: "1",
        name: "CRUNCHY POTATO SPINACH PAKODA (GF/DF)",
        price: "12.50",
        description:
          "Boiled smashed potatoes mixed with fresh spinach, herbs and spices the number of pieces served with a mild spicy tomato mayo base sauce.",
      },
      {
        id: "2",
        name: "CUMIN POTATO (GF/DF)",
        price: "10.50",
        description:
          "Boiled potato stirs fried with fried cumin seeds, garlic, red onion and served with green coriander.",
      },
      {
        id: "3",
        name: "CHICKEN LOLLYPOP (GF)",
        price: "13.00",
        description:
          "4 pieces of marinated chicken wings, combined all the meats together and deep-fried, served with homemade spicy dipping sauce.",
      },
      {
        id: "4",
        subMenu: true,
        subTitle: "Main Course",
      },
      {
        id: "5",
        name: "GOAT CURRY (GF/DF)",
        price: "28.00",
        description:
          "Slow-cooked goat meat with bone in Nepalese-style gravy, garnished with coriander. (Mild, Medium, Hot)",
      },
      {
        id: "6",
        name: "GOAT NEPALESE STYLE KABAB (GF/DF)",
        price: "30.00",
        description:
          "Slow-cooked goat meat with bone, prepared in ghee and Nepalese-style spices. Served dry and packed with flavour. GF (Gluten-Free). Perfect to enjoy with alcoholic drinks or Rice.",
      },
      {
        id: "7",
        name: "CHICKEN CURRY (GF/DF)",
        price: "26.00",
        description:
          "Diced chicken thigh pieces cooking with slow cooked Nepalese style gravy garnish with coriander. (Mild/Medium/Hot)",
      },
      {
        id: "8",
        name: "CHICKEN CHILLI (GF/DF)",
        price: "26.00",
        description:
          "Battered deep-fried Crispy chicken thigh cooked with chilli sauce and herbs, accompanied by onions, capsicum, and soy sauce.",
      },
      {
        id: "9",
        name: "SPINACH CHICKEN CURRY (GF)",
        price: "25.00",
        description:
          "Blended baby spinach cooked with Nepalese-style gravy and cream, with diced Chicken Pieces. (Mild/Medium/Hot)",
      },
      {
        id: "10",
        name: "SPINACH COTTAGE CHEESE CURRY (GF)",
        price: "25.00",
        description:
          "Blended baby spinach cooked with Nepalese-style gravy and cream, with diced cottage cheese. (Mild/Medium/Hot)",
      },
      {
        id: "11",
        name: "BAMBOO SHOOT POTATO CURRY (Alu Bodhi Tama) (GF/DF/Vegan)",
        price: "24.50",
        description:
          "A traditional Nepalese dish made with bamboo shoots, potatoes, and black-eyed beans, enhanced with sun-dried black lentil dumplings for an authentic touch. This dish can be enjoyed on its own as a soup or paired with rice.",
      },
      {
        id: "12",
        subMenu: true,
        subTitle: "Rice",
      },
      {
        id: "13",
        name: "PLAIN RICE (GF)",
        price: "6.00",
        description: "Basmati Rice go with All Curries.",
      },
      {
        id: "14",
        name: "PULAO RICE (GF)",
        price: "8.00",
        description: "Cooked in ghee with spices, green peas, and saffron.",
      },
      {
        id: "15",
        subMenu: true,
        subTitle: "Dessert",
      },
      {
        id: "16",
        name: "BLACK RICE PUDDING (GF)",
        price: "8.00",
        description:
          "Slow cooked rice in milk and sugar with fragrant spices served with pistachio and cashew. ",
        nextInfo:
          "or please ask our staff about the variety of desserts available in our cabinet.",
      },
      {
        id: "17",
        subMenu: true,
        subTitle: "Drinks",
      },
      {
        id: "18",
        name: "Plain Lassi",
        price: "9.00",
      },
      {
        id: "19",
        name: "Mango Lassi",
        price: "10.00",
      },
      {
        id: "20",
        name: "Special Milkshake Coffee/Chai/Mocha",
        price: "9.00",
      },
      {
        id: "21",
        name: "Smoothies (Mango/Banana/Mixed Berries/Strawberry)",
        price: "7.50",
      },
      {
        id: "22",
        name: "Milkshake / Thick shake (Strawberry/Peppermint/Caramel/Banana/Vanilla)",
        price: "8.00 / 9.00",
      },
      {
        id: "23",
        subMenu: true,
        subTitle: "Fresh Squeezed Juices",
        nextInfo: "Add Extra Ginger $0.50",
      },
      {
        id: "24",
        name: "DETOX JUICE (Celery, ginger, lemon, apple, celery, carrot, orange, and beetroot)",
        price: "10.50",
      },
      {
        id: "25",
        name: "GREEN JUICE (Cucumber/Spinach/Celery/Green Apple/Lemon)",
        price: "10.50",
      },
      {
        id: "26",
        name: "MIXED JUICE (Watermelon/Pineapple/Apple/Orange/Orange)",
        price: "9.00",
      },
      {
        id: "27",
        name: "SINGLE FRUIT JUICE (Watermelon/Pineapple/Apple/Orange/Orange)",
        price: "9.00",
      },
    ],
  },
  {
    id: "10",
    title: "SIDES",
    items: [
      {
        id: "1",
        name: "Wedges",
        price: "L 11.00 / S 7.00",
      },
      {
        id: "2",
        name: "Beer Battered Fries",
        price: "L 11.00 / S 7.00 ",
      },
    ],
  },
  {
    id: "11",
    title: "Extras",
    items: [
      {
        id: "1",
        name: "Whipped Cream / Ice cream / Sauces",
        price: "2.50",
      },
      {
        id: "2",
        name: "Grilled tomato 1/2 piece",
        price: "3.00 / 5.00",
      },
      {
        id: "3",
        name: "1 egg / 2 eggs",
        price: "3.00 / 6.00",
      },
      {
        id: "4",
        name: "Scramble eggs ",
        price: "6.00",
      },
      {
        id: "5",
        name: "2 pork sausage/smoked ham/mushroom/salmon/avocado",
        price: "6.00",
      },
      {
        id: "6",
        name: "1 hash brown / 2 hash brown or 1 piece toast / 2 piece toast",
        price: "3.50 / 6.00",
      },
    ],
  },
  {
    id: "12",
    title: "COFFEE",
    items: [
      {
        id: "1",
        name: "Short / Long black",
        price: "3.60 / 3.90",
      },
      {
        id: "2",
        name: "Flat / Cappuccino / Latte",
        price: "4.40",
      },
      {
        id: "3",
        name: "CHAI LATTE",
        price: "4.80",
      },
      {
        id: "4",
        name: "HOT CHOCOLATE MOCHA",
        price: "4.80",
      },
      {
        id: "5",
        name: "Short / Long M",
        price: "3.90 / 4.80",
      },
      {
        id: "6",
        name: "Turmeric Latte",
        price: "4.20",
      },
      {
        id: "7",
        subMenu: true,
        subTitle: "COFFEE EXTRAS",
      },
      {
        id: "8",
        name: "Large 12oz takeaway coffees are available with extra",
        price: "0.50",
      },
      {
        id: "9",
        name: "TEA FOR 1 / Tea For 2",
        price: "4.00 / 6.00",
      },
      {
        id: "10",
        name: "Mug",
        price: "0.90",
      },
      {
        id: "11",
        name: "DECAF",
        price: "0.30",
      },
      {
        id: "12",
        name: "EXTRA SHOT",
        price: "0.50",
      },
      {
        id: "13",
        name: "SYRUPS (Vanilla/Caramel/Hazelnut)",
        price: "0.90",
      },
      {
        id: "14",
        subMenu: true,
        subTitle: "Cold Beverage",
      },
      {
        id: "15",
        name: "Iced coffee/Chocolate/Mocha/Chai Latte",
        price: "7.00",
      },
      {
        id: "16",
        name: "Frappes (Coffee/Mocha/Chocolate/Chai)",
        price: "9.00",
      },
      {
        id: "17",
        name: "Iced Latte/Long Black",
        price: "5.50",
      },
    ],
  },
  {
    id: "13",
    title: "BOTTLED DRINKS",
    items: [
      {
        id: "1",
        name: "ICED TEA (Lemon/Peach/Mango/Green)",
        price: "5.00",
      },
      {
        id: "2",
        name: "SPRING WATER (600ML)",
        price: "4.50",
      },
      {
        id: "3",
        name: "SPARKLING WATER",
        price: "4.00",
      },
      {
        id: "4",
        name: "LEMON LIME BITTERS",
        price: "5.00",
      },
      {
        id: "5",
        name: "GINGER BEER",
        price: "5.00",
      },
      {
        id: "6",
        name: "POP TOP KIDS",
        price: "3.50",
      },
      {
        id: "7",
        name: "COKE (375MLSL)",
        price: "4.50",
      },
      {
        id: "8",
        name: "SPRING VALLEY JUICE (APPLE/ORANGE)",
        price: "5.00",
      },
    ],
  },
];
