import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  adminRoot: {
    display: "flex",
    padding: "4px 20px",
    height: "100vh",
    flexDirection: "column",
    alignItems: "baseline",
    backgroundColor: "#ffffff",
  },
  header: {
    padding: 10,
  },
  form: {
    width: "80%",
    alignSelf: "center",
    padding: 10,
  },
  errorText: {
    color: "#FF0000",
    marginTop: theme.spacing(1),
    fontSize: 14,
    alignSelf: "center",
  },
  loader: {
    border: "2px solid #3361F9",
    borderTop: "2px solid #ffffff",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    animation: `$deleteSpin 1s linear infinite`,
  },
  "@keyframes deleteSpin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export default useStyles;
