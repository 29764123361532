import L from "leaflet";
import { MapContainer, TileLayer, Marker, useMap, Popup } from "react-leaflet";

import { Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { renderToStaticMarkup } from "react-dom/server"; // Import renderToStaticMarkup

// css *required
import useStyles from "./styles";

// other init
const position: [number, number] = [-32.26337, 115.816431]; // Explicitly type position

//===================================================
// 1.Main Component
//===================================================
const ComponentResize = () => {
  const map = useMap();

  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  return null;
};

const Maps = () => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // Create a custom marker icon
  const customIcon = L.divIcon({
    className: "custom-icon",
    html: renderToStaticMarkup(
      <LocationOnIcon htmlColor="#FF0000" style={{ fontSize: "24px" }} />,
    ),
  });

  // 1-2. Store

  // showMain
  return (
    <Box className={classes.contentRoot}>
      <MapContainer
        center={position}
        zoom={14}
        scrollWheelZoom={false}
        className={classes.mapContainer}
      >
        <ComponentResize />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} icon={customIcon}>
          <Popup>Bliss Momos</Popup>
        </Marker>
      </MapContainer>
    </Box>
  );
};

//===================================================
// 2.Export
//===================================================
export default Maps;
