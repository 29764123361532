import React from "react";

import { Stack, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

// css *required

// other init

//===================================================
// 1.Main Component
//===================================================

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

const SnackBar = (props: any) => {
  // 1-1. useStyles *require

  const {
    isVisible,
    message,
    status,
    handleClose,
    vertical = "top",
    horizontal = "right",
  } = props;

  // 1-2. Store

  // showMain
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={isVisible}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={status} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

//===================================================
// 2.Export
//===================================================
export default SnackBar;
