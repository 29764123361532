import { makeStyles } from "@mui/styles";
import { darkenColor } from "../../common/Utility";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    "& .MuiGrid-root .MuiGrid-item": {
      paddingLeft: 0,
    },
  },
  root: {
    padding: "20px 60px",
    display: "flex",
    flexDirection: "column",
    gap: 60,
    "& .MuiGrid-root .MuiGrid-item": {
      paddingLeft: 16,
    },
  },
  dateTimeWrapper: {
    marginTop: "-8px !important",
    "& .MuiStack-root .css-11a8txn-MuiStack-root": {
      width: "inherit !important",
    },
  },
  date: {
    height: 56,
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: "transparent",
    "& .MuiStack-root": {
      width: "inherit !important",
    },
  },
  errors: {
    border: "1px solid #FF0000 !important",
  },
  submitBtn: {
    backgroundColor: "#d9baa0",
    borderRadius: 20,
    width: 120,
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: 10,
    cursor: "pointer",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: darkenColor("#d9baa0", 10),
    },
  },
  customLoader: {
    border: "2px solid #f3f3f3",
    borderTop: "2px solid #000000",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    animation: `$spin 1s linear infinite`,
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  notes: {
    color: "#00000080",
    fontSize: "12px !important",
    marginTop: "10px !important",
    textAlign: "start",
  },
}));

export default useStyles;
