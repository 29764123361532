import { useEffect, useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import emailjs from "@emailjs/browser";

import { Box, Grid, Typography, TextField } from "@mui/material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";

// atomic
import { Dashboard } from "../../templates";
import { SnackBar, ReservationSuccessModal } from "../../atoms";
import { FirebaseConfig } from "../../constants";

// css *required
import useStyles from "./styles";

interface ReservationData {
  firstName: string;
  lastName: string;
  email: string;
  numberofPeople: string;
  datetime: null;
  phoneNumber: string;
}

interface ErrorData {
  firstName: string;
  lastName: string;
  email: string;
  numberofPeople: string;
  datetime: string;
  phoneNumber: string;
}

//===================================================
// 1.Main Component
//===================================================
const Contactus = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // 1-2. Store
  const [reservationData, setReservationData] = useState<ReservationData>({
    firstName: "",
    lastName: "",
    email: "",
    numberofPeople: "",
    datetime: null,
    phoneNumber: "",
  });

  const [errors, setErrors] = useState<ErrorData>({
    firstName: "",
    lastName: "",
    email: "",
    numberofPeople: "",
    datetime: "",
    phoneNumber: "",
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSnackbarSuccess, setSnackbarSuccess] = useState<boolean>(false);
  const [isSnackbarError, setSnackbarError] = useState<boolean>(false);
  const [visibleSuccessModal, setVisibleSuccessModal] =
    useState<boolean>(false);

  // Set the title to your desired text
  useEffect(() => {
    document.title = "Reservations - Bliss Momos";
  }, []);

  const shouldDisableTime = (value: Dayjs, view: any): boolean => {
    // Perform checks based on the selected date and time
    const isWeekend = value.day() === 0 || value.day() === 6;

    if (view === "hours") {
      // Disable specific hours based on your requirements
      if (isWeekend) {
        return value.hour() < 8 || value.hour() >= 14;
      } else {
        return value.hour() < 7 || value.hour() >= 14;
      }
    } else {
      // Disable specific minutes or other views if needed
      return false;
    }
  };

  return (
    <Box className={classes.appContainer}>
      <SnackBar
        isVisible={isSnackbarSuccess}
        status={"success"}
        message={"Your Reservation Was Successful!"}
        handleClose={() => setSnackbarSuccess(false)}
      />
      <SnackBar
        isVisible={isSnackbarError}
        status={"error"}
        message={"Reservation Failed!"}
        handleClose={() => setSnackbarError(false)}
      />
      <ReservationSuccessModal
        isVisible={visibleSuccessModal}
        handleClose={() => setVisibleSuccessModal(false)}
      />
      <Dashboard>
        <Box className={classes.root}>
          <Typography variant="h3" align="center">
            Reservations
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" align="center">
                Bliss Momos
              </Typography>
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Shop T7, 15 The Strand, Wellard, WA, Australia
              </Typography>
              <Typography variant="body1">+61 8 9419 7944</Typography>
              <Typography variant="body1">
                sunil.shrestha@blissmomoscafe.com
              </Typography>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                className={classes.notes}
              >
                <q>
                  <em>
                    NOTES :- If you wish to make a reservation outside of our
                    regular business hours, please don't hesitate to reach out
                    to us at sunil.shrestha@blissmomoscafe.com.
                  </em>
                </q>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1">Name (required)</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={reservationData?.firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onTextChange(event, setReservationData, setErrors, true)
                    }
                    error={!!errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={reservationData?.lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onTextChange(event, setReservationData, setErrors, true)
                    }
                    error={!!errors.lastName}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1">
                    Email & Number of People (required)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={reservationData?.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onTextChange(event, setReservationData, setErrors)
                    }
                    error={!!errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Number of People"
                    name="numberofPeople"
                    type="number"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={reservationData?.numberofPeople}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onTextChange(event, setReservationData, setErrors)
                    }
                    error={!!errors.numberofPeople}
                    inputProps={{
                      min: 1,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    onKeyDown={(event) => formatInput(event)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1">
                    Phone Number & Date Time (required)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={reservationData?.phoneNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onTextChange(event, setReservationData, setErrors)
                    }
                    error={!!errors.phoneNumber}
                    inputProps={{
                      min: 1,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    onKeyDown={(event) => formatInput(event)}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.dateTimeWrapper}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["Date Time"]}>
                      <DemoItem>
                        <DateTimePicker
                          views={["year", "month", "day", "hours", "minutes"]}
                          closeOnSelect={false}
                          onChange={(value) => {
                            handleDateChange(
                              value?.toString(),
                              setReservationData,
                              setErrors,
                            );
                          }}
                          className={`${classes.date} ${
                            errors.datetime && classes.errors
                          }`}
                          disablePast
                          defaultValue={dayjs()}
                          value={reservationData?.datetime}
                          maxDate={dayjs().add(30, "day")}
                          shouldDisableTime={(value, view) =>
                            shouldDisableTime(value, view)
                          }
                          ampm={false}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    className={classes.submitBtn}
                    onClick={(event: any) => {
                      if (isLoading) return;
                      onsubmit({
                        event,
                        reservationData,
                        setReservationData,
                        errors,
                        setErrors,
                        setLoading,
                        setSnackbarSuccess,
                        setSnackbarError,
                        setVisibleSuccessModal,
                      });
                    }}
                  >
                    {isLoading ? (
                      <Box className={classes.customLoader} />
                    ) : (
                      "Submit"
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dashboard>
    </Box>
  );
};

//===================================================
// 2.Export
//===================================================
export default Contactus;

//===================================================
// 3.propTypes and defaultProps
//===================================================

//3-1. propTypes
Contactus.propTypes = {};

//3-2. defaultProps
Contactus.defaultProps = {};

//===================================================
// 4.Functions *require showMain()
//===================================================

//===================================================
// 5.Actions
//===================================================
/**
 * set value on reservationData
 * @param  event
 * @param  setReservationData
 */
const onTextChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setReservationData: React.Dispatch<React.SetStateAction<any>>,
  setErrors: React.Dispatch<React.SetStateAction<any>>,
  isOnlyText: boolean = false,
) => {
  const label = event.target.name;
  let value = event.target.value;

  if (isOnlyText) {
    value = event.target.value.replace(/[^A-Za-z]/g, "");
  }

  setReservationData((prevState: any) => ({
    ...prevState,
    [label]: value,
  }));

  setErrors((prevState: any) => ({
    ...prevState,
    [label]: "",
  }));
};

/**
 * set value on reservationData
 * @param  date
 * @param  setReservationData
 */
const handleDateChange = (
  date: string | null | undefined,
  setReservationData: React.Dispatch<React.SetStateAction<any>>,
  setErrors: React.Dispatch<React.SetStateAction<any>>,
) => {
  if (date !== null) {
    const parsedDate = dayjs(date);
    const currentDate = dayjs();
    const maxAdvanceDays = 30;

    if (
      parsedDate.isValid() &&
      parsedDate.isBefore(currentDate.add(maxAdvanceDays, "day"))
    ) {
      setReservationData((prevState: any) => ({
        ...prevState,
        datetime: parsedDate.toString(),
      }));
      setErrors((prevState: any) => ({
        ...prevState,
        datetime: "",
      }));
    }
  }
};

const onsubmit = async (props: {
  event: any;
  reservationData: ReservationData;
  setReservationData: React.Dispatch<React.SetStateAction<ReservationData>>;
  errors: ErrorData;
  setErrors: React.Dispatch<React.SetStateAction<ErrorData>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarError: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    reservationData,
    setErrors,
    setLoading,
    setReservationData,
    setSnackbarSuccess,
    setSnackbarError,
    setVisibleSuccessModal,
  } = props;
  try {
    const isValidated = handleValidate(reservationData, setErrors);
    setLoading(true);

    if (isValidated) {
      const emailData = {
        firstName: reservationData.firstName,
        lastName: reservationData.lastName,
        email: reservationData.email,
        numberofPeople: reservationData.numberofPeople,
        datetime: reservationData.datetime,
        phoneNumber: reservationData.phoneNumber,
      };

      const form = document.createElement("form");
      // Append form fields
      form.innerHTML = `
      <input type="text" name="firstName" value="${emailData.firstName}">
      <input type="text" name="lastName" value="${emailData.lastName}">
      <input type="email" name="email" value="${emailData.email}">
      <input type="text" name="numberofPeople" value="${emailData.numberofPeople}">
      <input type="text" name="datetime" value="${emailData.datetime}">
      <input type="text" name="phoneNumber" value="${emailData.phoneNumber}">
      `;

      // Append the form to the document's body (or an appropriate location)
      // document.body.appendChild(form);

      // old
      // const emailResponse = await emailjs.sendForm(
      //   "service_hc923bq",
      //   "template_dbnel29",
      //   form,
      //   "4lLvm3M5PAiOl3Hzq",
      // );

      // customer
      // const emailResponse = await emailjs.sendForm(
      //   "service_fyd3ra7",
      //   "template_1ipzwvb",
      //   form,
      //   "F9tT7RsqPSTtNc1Zw",
      // );

      // original
      const emailResponse = await emailjs.sendForm(
        "service_5qrh3dd",
        "template_dbnel29",
        form,
        "4lLvm3M5PAiOl3Hzq",
      );

      let emailStatus = false;

      if (emailResponse.status === 200) {
        emailStatus = true;
      }

      const data = {
        firstName: reservationData.firstName,
        lastName: reservationData.lastName,
        email: reservationData.email,
        numberofPeople: reservationData.numberofPeople,
        datetime: reservationData.datetime,
        phoneNumber: reservationData.phoneNumber,
        emailStatus,
      };

      const yourCollectionRef = collection(
        FirebaseConfig.db,
        "blissReservations",
      );

      const docRef = await addDoc(yourCollectionRef, data);
      if (docRef) {
        setLoading(false);
        setSnackbarSuccess(true);
        setVisibleSuccessModal(true);
        setReservationData({
          firstName: "",
          lastName: "",
          email: "",
          numberofPeople: "",
          datetime: null,
          phoneNumber: "",
        });
      } else {
        setSnackbarError(true);
      }
    } else {
      setLoading(false);
    }
  } catch (error) {
    console.error("An error occurred:", error);
    setLoading(false);
  }
};

/**
 * validate input
 * @param {*} props
 */
const handleValidate = (
  reservationData: ReservationData,
  setErrors: React.Dispatch<React.SetStateAction<ErrorData>>,
) => {
  const { firstName, lastName, email, numberofPeople, datetime, phoneNumber } =
    reservationData;
  let errors: ErrorData = {
    firstName: "",
    lastName: "",
    email: "",
    numberofPeople: "",
    datetime: "",
    phoneNumber: "",
  };
  let formIsValid = true;

  //firstName
  if (!firstName || firstName.trim.length < 0) {
    formIsValid = false;
    errors["firstName"] = "First name is empty";
  }
  //lastName
  if (!lastName || lastName.trim.length < 0) {
    formIsValid = false;
    errors["lastName"] = "lastName is empty";
  }
  //email
  if (!email || email.trim.length < 0) {
    formIsValid = false;
    errors["email"] = "email is empty";
  }
  //email validate
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  if (!emailPattern.test(email)) {
    formIsValid = false;
    errors["email"] = "Email is not a valid address!";
  }
  //numberofPeople
  if (!numberofPeople || numberofPeople.trim.length < 0) {
    formIsValid = false;
    errors["numberofPeople"] = "numberofPeople is empty";
  }
  //datetime
  if (!datetime) {
    formIsValid = false;
    errors["datetime"] = "datetime is empty";
  }
  if (!phoneNumber || phoneNumber.trim.length < 0) {
    formIsValid = false;
    errors["phoneNumber"] = "phoneNumber is empty";
  }

  setErrors(errors);
  return formIsValid;
};

/**
 * Prevent characters that are not numbers ("e", "E", ".", "+", "-" & ".")
  @param {} event
 */
const formatInput = (event: any) => {
  let checkIfNum;
  if (event.key !== undefined) {
    checkIfNum =
      event.key === "e" ||
      event.key === "E" ||
      event.key === "+" ||
      event.key === "-" ||
      event.key === ".";
  } else {
    checkIfNum =
      event.keyCode === 69 ||
      event.keyCode === 187 ||
      event.keyCode === 189 ||
      event.keyCode === 190;
  }
  return checkIfNum && event.preventDefault();
};
