import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4px 20px",
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    backgroundColor: "#ffffff20 !important",
  },
  header: {
    padding: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableHead: {
    backgroundColor: "#fafafa",
  },
  customLoaderWrapper: {
    display: "flex",
    height: "86vh",
    alignItems: "center",
    justifyContent: "center",
  },
  customLoader: {
    border: "6px solid #3361F9",
    borderTop: "6px solid #ffffff",
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    animation: `$spin 1s linear infinite`,
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  loader: {
    border: "1px solid #3361F9",
    borderTop: "1px solid #ffffff",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    animation: `$spin 1s linear infinite`,
  },
  "@keyframes deleteSpin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export default useStyles;
