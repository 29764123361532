import React, { useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// mui
import { Button, TextField, Box, IconButton, Typography } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

// atomic
import { FirebaseConfig } from "../../constants";

// css *required
import useStyles from "./styles";
import { SnackBar } from "../../atoms";

interface FormData {
  email: string;
  password: string;
}

interface FormErrorData {
  email: string;
  password: string;
}

type NavigateFunction = (path: string) => void;

//===================================================
// 1.Main Component
//===================================================
const AdminLogin = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();
  const navigate = useNavigate();

  // 1-2. useState
  const adminLogin = localStorage.getItem("adminLogin");

  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const [errorData, setErrorData] = useState<FormErrorData>({
    email: "",
    password: "",
  });

  const [isLoading, setLoading] = useState<Boolean>(false);
  const [isSnackbarError, setSnackbarError] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  useEffect(() => {
    document.title = "AdminPanel - Bliss Momos";
  }, []);

  useEffect(() => {
    if (adminLogin) {
      navigate("/reservationhistory");
    }
  }, []);

  return (
    <Box className={classes.adminRoot}>
      <IconButton className={classes.header} onClick={() => navigate("/")}>
        <HomeOutlinedIcon />
      </IconButton>

      <Box className={classes.form}>
        <TextField
          label="Email"
          id="email"
          name="email"
          margin="dense"
          fullWidth
          error={errorData.email ? true : false}
          InputLabelProps={{ shrink: true }}
          value={formData?.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(event, setFormData, setErrorData)
          }
          helperText={errorData.email}
        />
        <TextField
          label="Password"
          id="standard-password-input"
          name="password"
          type="password"
          margin="dense"
          fullWidth
          error={errorData.password ? true : false}
          InputLabelProps={{ shrink: true }}
          value={formData?.password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(event, setFormData, setErrorData)
          }
          helperText={errorData.password}
        />
        <Button
          fullWidth
          size="large"
          variant="outlined"
          color="info"
          sx={{
            marginTop: "40px",
            height: "40px",
          }}
          disabled={false}
          onClick={() =>
            onLoginClicked(
              formData,
              setLoading,
              setFormData,
              setErrorData,
              setSnackbarError,
              setSnackbarMessage,
              navigate,
            )
          }
        >
          {isLoading ? (
            <Box className={classes.loader} />
          ) : (
            <Typography>LOGIN</Typography>
          )}
        </Button>
      </Box>
      <SnackBar
        status="error"
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        isVisible={isSnackbarError}
        handleClose={() => setSnackbarError(false)}
      />
    </Box>
  );
};

//===================================================
// 2.Export
//===================================================
export default AdminLogin;

//===================================================
// 3.propTypes and defaultProps
//===================================================

//3-1. propTypes
AdminLogin.propTypes = {};

//3-2. defaultProps
AdminLogin.defaultProps = {};

//===================================================
// 4.Functions *require showMain()
//===================================================

//===================================================
// 5.Actions
//===================================================

/**
 * change text of input field
 */
const onChangeField = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFormData: React.Dispatch<React.SetStateAction<any>>,
  setErrorData: React.Dispatch<React.SetStateAction<any>>,
) => {
  const label = event.target.name;
  let value = event.target.value;

  setFormData((prevState: any) => ({
    ...prevState,
    [label]: value,
  }));
  setErrorData({
    email: false,
    password: false,
  });
};

/**
 * login
 * @param formData
 * @param setLoading
 * @param setFormData
 */
const onLoginClicked = async (
  formData: FormData,
  setLoading: React.Dispatch<React.SetStateAction<any>>,
  setFormData: React.Dispatch<React.SetStateAction<any>>,
  setErrorData: React.Dispatch<React.SetStateAction<any>>,
  setSnackbarError: React.Dispatch<React.SetStateAction<any>>,
  setSnackbarMessage: React.Dispatch<React.SetStateAction<any>>,
  navigate: NavigateFunction,
) => {
  try {
    setLoading(true);
    await timeout(200);
    const { email, password } = formData;
    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

    if (!email || email.trim.length < 0) {
      setErrorData((prevState: any) => ({
        ...prevState,
        email: "Email is required!",
      }));
      setLoading(false);
      return;
    }

    if (!emailPattern.test(email)) {
      setLoading(false);
      setErrorData((prevState: any) => ({
        ...prevState,
        email: "Email is not a valid address!",
      }));
      return;
    }

    if (!password || password.trim.length < 0) {
      setErrorData((prevState: any) => ({
        ...prevState,
        password: "Password is required!",
      }));
      setLoading(false);
      return;
    }

    // const email = 'sunil.shrestha@blissmomoscafe.com';
    // const password = 'XdgeX_0vXrC&';
    const userCredential = await signInWithEmailAndPassword(
      FirebaseConfig.auth,
      email,
      password,
    );
    if (userCredential.user) {
      await localStorage.setItem("adminLogin", "true");
      setLoading(false);
      setFormData({
        email: "",
        password: "",
      });
      window.location.reload();
    }
  } catch (error) {
    setLoading(false);
    if (error instanceof Error) {
      console.error("Error message:", error.message);
      setSnackbarError(true);
      setSnackbarMessage(error.message);
    }
  }
};

/**
 * for delaying the process
 * number = second/s
 * @param {number} delay - The delay time in milliseconds
 */
const timeout = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};
