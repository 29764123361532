import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    headerText: {
      color: "#00000080 !important",
      fontWeight: "bold !important",
    },
    text: {
      color: "#00000090 !important",
    },
    button: {
      color: "#d9baa0 !important",
      fontWeight: "bold !important",
    },
  };
});

export default useStyles;
