import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import {
  Home,
  About,
  // OurServices,
  Menu,
  Contact,
  ReservationHistory,
  AdminLogin,
} from "./pages";

// Check if adminLogin is true in localStorage
const adminLogin = localStorage.getItem("adminLogin") === "true";
//===================================================
// 1.Main Component
//===================================================
const AppRouter = () => {
  // 1-1. useStyles *require

  // 1-2. Store

  // showMain
  return showMain();
};

//===================================================
// 2.Export
//===================================================
export default AppRouter;

//===================================================
// 3.propTypes and defaultProps
//===================================================

//3-1. propTypes
AppRouter.propTypes = {};

//3-2. defaultProps
AppRouter.defaultProps = {};

//===================================================
// 4.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        {/* <Route path='/our-services' element={<OurServices />} /> */}
        <Route path="/menu" element={<Menu />} />
        <Route path="/reservations" element={<Contact />} />
        <Route path="/adminpanel" element={<AdminLogin />} />
        <Route
          path="/reservationhistory"
          element={
            adminLogin ? <ReservationHistory /> : <Navigate to="/adminpanel" />
          }
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

//===================================================
// 5.Actions
//===================================================
