import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "100px 60px",
    display: "flex",
    flexDirection: "column",
    gap: 40,
  },
}));

export default useStyles;
