import { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { AssistantDirection } from "@mui/icons-material";

import { Dashboard } from "../../templates";
import { Maps } from "../../atoms";

import useStyles from "./styles";

//===================================================
// 1.Main Component
//===================================================
const Home = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // Set the title to your desired text
  useEffect(() => {
    document.title = "Bliss Momos";
  }, []);

  return (
    <Dashboard>
      <div className={classes.root}>
        <div className={classes.heroWrapper}>
          <div className={classes.overlay}>
            <Typography
              variant="h3"
              fontWeight={"bold"}
              letterSpacing={4}
              className={classes.imageText}
            >
              <em className={classes.text}>Coffee, Food</em>
            </Typography>
            <Typography
              variant="h3"
              fontWeight={"bold"}
              letterSpacing={4}
              className={classes.imageText}
            >
              <em className={classes.text}>&</em>
            </Typography>
            <Typography
              variant="h3"
              fontWeight={"bold"}
              letterSpacing={4}
              className={classes.imageText}
            >
              <em className={classes.text}>Friendship</em>
            </Typography>
          </div>
        </div>
        <div className={classes.centerText}>
          <Typography variant="h5" lineHeight={1.5}>
            Mission Statement
          </Typography>
          <Typography variant="body1" lineHeight={1.5}>
            Our mission is to deliver exceptional coffee and delicious food
            infused with Nepalese ingredients prepared in a comforting homemade
            style. We strive to offer an inviting environment where customers
            experience a warm and friendly atmosphere. Our core value is
            celebrating the joys of good coffee, food and friendship.
          </Typography>
          <Typography variant="h5" lineHeight={1.5}>
            <em>
              <q>
                Bliss Momos now proudly offers the finest coffee, delectable
                breakfast options, & mouthwatering momos every single day
              </q>
            </em>
          </Typography>
          <div>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: 8,
              }}
            >
              <AssistantDirection htmlColor="#945C44" />
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Shop T7, 15 The Strand, Wellard, WA, Australia
              </Typography>
            </Box>
            <Maps />

            <Typography
              variant="body1"
              fontWeight={"bold"}
              style={{
                marginTop: 12,
              }}
            >
              CAFÉ OPEN:
            </Typography>
            <Typography variant="body1">
              MONDAY TO FRIDAY: - 7:00am to 2.30pm
            </Typography>
            <Typography variant="body1">
              SATURDAY, SUNDAY, AND PUBLIC HOLIDAY: - 8:00am to 2.30pm
            </Typography>
            <Typography
              variant="body1"
              style={{
                margin: "14px 0",
              }}
            >
              <em>
                <q>
                  For birthday parties and all special occasions, we strongly
                  encourage you to secure your reservation in advance. This
                  ensures that we can perfectly accommodate your celebration,
                  creating an unforgettable experience for you and your guests.
                  To make your reservation, please feel free to reach out to us
                  via phone, email, or our convenient online reservation system.
                  We eagerly anticipate the opportunity to assist you in
                  crafting a remarkable and joyous event for your special day!
                </q>
              </em>
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              (Note: - Please Check our board menu for everyday specials or a
              talk to our friendly front of house staff).
            </Typography>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

//===================================================
// 2.Export
//===================================================
export default Home;
