import { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

// atomic
import { FirebaseConfig } from "../../constants";

// css *required
import useStyles from "./styles";
import { Typography } from "@mui/material";
import { SnackBar } from "../../atoms";

//===================================================
// 1.Main Component
//===================================================
const ReservationHistory = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();
  const navigate = useNavigate();

  // 1-2. useState
  const [reservationData, setReservationData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<Boolean>(false);
  const [listIndex, setListIndex] = useState<String>("");
  const [isSnackbarSuccess, setSnackbarSuccess] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Reservation History - Bliss Momos";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(
          collection(FirebaseConfig.db, "blissReservations"),
        );
        const documents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReservationData(documents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <SnackBar
        isVisible={isSnackbarSuccess}
        status={"success"}
        message={"Reservation List Delete Successful!"}
        handleClose={() => setSnackbarSuccess(false)}
      />
      <div className={classes.header}>
        <Tooltip title="Home">
          <HomeOutlinedIcon onClick={() => navigate("/")} />
        </Tooltip>
        <Tooltip title="Logout">
          <LogoutOutlinedIcon
            onClick={async () => {
              try {
                await signOut(FirebaseConfig.auth);
                await localStorage.removeItem("adminLogin");
                navigate("/adminpanel");
              } catch (error) {
                console.error("Error logging out:", error);
              }
            }}
          />
        </Tooltip>
      </div>
      {isLoading ? (
        <Box className={classes.customLoaderWrapper}>
          <Box className={classes.customLoader} />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>S.N</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>People Count</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email Status</TableCell>
                <TableCell>Reservation Date Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservationData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography>Reservation list not found.</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                reservationData.map((data, index) => {
                  return (
                    <TableRow
                      key={data.id}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "transparent" : "#fafafa",
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {data.firstName} {data.lastName}
                      </TableCell>
                      <TableCell>{data.email}</TableCell>
                      <TableCell>{data.numberofPeople}</TableCell>
                      <TableCell>{data.phoneNumber}</TableCell>
                      <TableCell>
                        {data.emailStatus ? "Email Deliver" : "Email Failed"}
                      </TableCell>
                      <TableCell>{data?.datetime?.replace("T", " ")}</TableCell>
                      <TableCell>
                        {listIndex === data.id && isDeleteLoading ? (
                          <Box className={classes.loader} />
                        ) : (
                          <Tooltip title="Delete">
                            <DeleteForeverOutlinedIcon
                              htmlColor="#FF0000"
                              onClick={async () => {
                                try {
                                  setListIndex(data.id);
                                  setDeleteLoading(true);
                                  await deleteDoc(
                                    doc(
                                      collection(
                                        FirebaseConfig.db,
                                        "blissReservations",
                                      ),
                                      data.id,
                                    ),
                                  );
                                  const querySnapshot = await getDocs(
                                    collection(
                                      FirebaseConfig.db,
                                      "blissReservations",
                                    ),
                                  );
                                  const documents = querySnapshot.docs.map(
                                    (doc) => ({
                                      id: doc.id,
                                      ...doc.data(),
                                    }),
                                  );
                                  setReservationData(documents);
                                  setDeleteLoading(false);
                                  setListIndex("");
                                  setSnackbarSuccess(true);
                                } catch (error) {
                                  setDeleteLoading(false);
                                  console.error(
                                    "Error deleting document:",
                                    error,
                                  );
                                }
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

//===================================================
// 2.Export
//===================================================
export default ReservationHistory;

//===================================================
// 3.propTypes and defaultProps
//===================================================

//3-1. propTypes
ReservationHistory.propTypes = {};

//3-2. defaultProps
ReservationHistory.defaultProps = {};

//===================================================
// 4.Functions *require showMain()
//===================================================

//===================================================
// 5.Actions
//===================================================
