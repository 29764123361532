export function darkenColor(color: any, percent: number) {
  // Ensure the percent is between 0 and 100
  percent = Math.max(0, Math.min(100, percent));

  // Convert the color to RGB
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  // Calculate the darkened color components
  const darkenedR = Math.floor(r * (1 - percent / 100));
  const darkenedG = Math.floor(g * (1 - percent / 100));
  const darkenedB = Math.floor(b * (1 - percent / 100));

  // Convert the darkened color components back to hexadecimal
  const darkenedColor = `#${darkenedR.toString(16).padStart(2, "0")}${darkenedG
    .toString(16)
    .padStart(2, "0")}${darkenedB.toString(16).padStart(2, "0")}`;

  return darkenedColor;
}
