import { useState } from "react";

// atomic
import { useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  ListItemAvatar,
} from "@mui/material";
import {
  Menu,
  Close,
  HomeOutlined,
  EditCalendarOutlined,
  MessageOutlined,
  ContactsOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

// image
import { BlissMomos } from "../../assets";
import useStyles from "./index.css";

// css *required

// Define the prop types for the styled component
type ItemProps = {
  isActive: boolean;
};

//===================================================
// 1.Main Component
//===================================================
const AppHeader = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // 1-2. Store

  const pathName = location.pathname;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Paper
      sx={{
        p: 2,
        boxShadow: "none",
        backgroundColor: "#f5f1e8",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={3} md={4} className={classes.appIconWrapper}>
          <img
            src={BlissMomos}
            alt="logo"
            className={classes.appIcon}
            onClick={() => navigate("/")}
          />
        </Grid>
        <Grid item xs={0} md={8} className={classes.menu}>
          <Item
            isActive={pathName === "/about-us"}
            onClick={() => navigate("/about-us")}
          >
            About Us
          </Item>
          {/* <Item
            isActive={pathName === "/our-services"}
            onClick={() => navigate("/our-services")}
          >
            Our Services
          </Item> */}
          <Item
            isActive={pathName === "/menu"}
            onClick={() => navigate("/menu")}
          >
            Cafe Menu
          </Item>
          <Item
            isActive={false}
            style={{
              backgroundColor:
                pathName === "/reservations" ? "#D9BAA0" : "#000000",
              borderRadius: 300,
              color: "#FFFFFF",
              fontWeight: "bold",
              padding: 16,
            }}
            onClick={() => navigate("/reservations")}
          >
            Reservations
          </Item>
        </Grid>
        <Grid item xs={9} md={0} className={classes.drawerMenuIcon}>
          {isMenuOpen ? (
            <IconButton color="inherit" onClick={toggleMenu}>
              <Close />
            </IconButton>
          ) : (
            <IconButton color="inherit" onClick={toggleMenu}>
              <Menu />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Drawer anchor="bottom" open={isMenuOpen} onClose={toggleMenu}>
        <div className={classes.drawer}>
          <List className={classes.drawerList}>
            <ListItem alignItems="center" onClick={() => navigate("/")}>
              <ListItemAvatar>
                <HomeOutlined
                  htmlColor={pathName === "/" ? "#D9BAA0" : "#000000"}
                />
              </ListItemAvatar>
              <MobileItem isActive={pathName === "/"}>Home</MobileItem>
            </ListItem>
            <ListItem alignItems="center" onClick={() => navigate("/about-us")}>
              <ListItemAvatar>
                <ContactsOutlined
                  htmlColor={pathName === "/about-us" ? "#D9BAA0" : "#000000"}
                />
              </ListItemAvatar>
              <MobileItem isActive={pathName === "/about-us"}>
                About Us
              </MobileItem>
            </ListItem>
            <ListItem alignItems="center" onClick={() => navigate("/menu")}>
              <ListItemAvatar>
                <EditCalendarOutlined
                  htmlColor={pathName === "/menu" ? "#D9BAA0" : "#000000"}
                />
              </ListItemAvatar>
              <MobileItem isActive={pathName === "/menu"}>Cafe Menu</MobileItem>
            </ListItem>
            <ListItem
              alignItems="center"
              onClick={() => navigate("/reservations")}
            >
              <ListItemAvatar>
                <MessageOutlined
                  htmlColor={
                    pathName === "/reservations" ? "#D9BAA0" : "#000000"
                  }
                />
              </ListItemAvatar>
              <MobileItem isActive={pathName === "/reservations"}>
                Reservations
              </MobileItem>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </Paper>
  );
};

//===================================================
// 2.Export
//===================================================
export default AppHeader;

// Styled Component
const Item = styled(
  ({ isActive, ...rest }: ItemProps & React.ComponentProps<typeof Paper>) => (
    <Paper {...rest} />
  ),
)(({ theme, isActive }: { theme: any; isActive: boolean }) => ({
  ...theme.typography,
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(1),
  backgroundColor: "transparent",
  color: isActive ? "#D9BAA0" : theme.palette.text.primary,
  cursor: "pointer",
  fontWeight: isActive ? "bold" : "normal",
  fontSize: 18,
  borderBottom: isActive ? "2px solid #D9BAA0" : "none",
  borderRadius: 0,
}));

const MobileItem = styled(
  ({ isActive, ...rest }: ItemProps & React.ComponentProps<typeof Paper>) => (
    <Paper {...rest} />
  ),
)(({ theme, isActive }: { theme: any; isActive: boolean }) => ({
  ...theme.typography,
  cursor: "pointer",
  boxShadow: "none",
  backgroundColor: "transparent",
  color: isActive ? "#D9BAA0" : theme.palette.text.primary,
  fontSize: 16,
  fontWeight: isActive ? "bold" : "normal",
}));
