import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  appIconWrapper: {
    maxHeight: "100px",
    cursor: "pointer",
    paddingLeft: "40px",
  },
  appIcon: {
    width: 120,
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 16,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  drawerMenuIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawer: {
    backgroundColor: "#F5F1E8",
    padding: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerList: {
    width: "100%",
    maxWidth: 260,
  },
}));

export default useStyles;
