import { Grid } from "@mui/material";

// atomic

// css *required
import useStyles from "./index.css";

//===================================================
// 1.Main Component
//===================================================
const Contents = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // 1-2. Store

  const initialProps = {
    ...props,
    classes,
  };

  // showMain
  return showMain(initialProps);
};

//===================================================
// 2.Export
//===================================================
export default Contents;

//===================================================
// 3.propTypes and defaultProps
//===================================================

//3-1. propTypes
Contents.propTypes = {};

//3-2. defaultProps
Contents.defaultProps = {};

//===================================================
// 4.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props: any) => {
  const { children } = props;
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

//===================================================
// 5.Actions
//===================================================
