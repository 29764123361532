import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f5f1e8",
    minHeight: "100vh",
    justifyContent: "space-between",
  },
  grow: {
    flexGrow: 1,
  },
}));

export default useStyles;
