import { makeStyles } from "@mui/styles";
import { CafeImage } from "../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      color: "#945C44",
    },
  },
  heroWrapper: {
    position: "relative",
    background: `url(${CafeImage})`,
    backgroundRepeat: "no-repeat",
    height: "66vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  overlay: {
    backgroundColor: "#00000020",
    display: "flex",
    height: "inherit",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  imageText: {
    textAlign: "center",
    "&.MuiTypography-root": {
      color: "#FFFFFF",
    },
  },
  text: {
    // background: "linear-gradient(to right, #ff00ff, #00ff00)",
    // WebkitBackgroundClip: "text",
    // color: "transparent",
    WebkitTextStroke: "2px #000000",
  },
  centerText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "80px 20px",
    gap: 16,
  },
  betterText: {
    "&.MuiTypography-root": {
      fontStyle: "italic",
      fontSize: 80,
      textAlign: "center",
    },
  },
}));

export default useStyles;
